<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  props: {
    label: {
      type: String,
      default: '',
    },
    leftIndent: {
      type: String,
      default: '0px',
    },
  },
})
</script>

<template>
  <div class="TitleContentWrap">
    <div class="labelWrap">
      <span v-if="!$slots.label">{{ label }}</span>
      <slot v-if="$slots.label" name="label" />
    </div>
    <div class="contentWrap" :style="`padding-left: ${leftIndent}`">
      <slot />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.TitleContentWrap {
  .labelWrap {
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.85);
    text-align: left;
    font-style: normal;
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    &::before {
      content: '';

      display: inline-block;
      width: 4px;
      height: 16px;
      margin-right: 8px;
      background: #9155fd;
    }
  }
  .contentWrap{
    width: 100%;
  }
}
</style>
