<template>
  <div class="IndexTableLayout">
    <div class="headerWrap pb-5" v-if="$slots.header">
      <slot name="header">这里是头部</slot>
    </div>

    <div class="topWrap mb-5 pl-5 pr-5" v-if="$slots.top">
      <slot name="top">这里是头部与内容中间</slot>
    </div>

    <div class="content">
      <slot>这里是主要区域</slot>
    </div>

    <div class="footerWrap" v-if="$slots.footer">
      <slot name="footer"> 这里是底部 </slot>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  setup() {
    
  },
})
</script>


<style lang="scss" scoped>
.IndexTableLayout {
  background: #fff;
  box-sizing: border-box;
  height: 100%;
  position: relative;
  border-radius: 6px;
  display: flex;
  flex-direction: column;

  .content {
    height: calc(100% - 160px);
  }

  .footerWrap {
    height: 60px;
    box-sizing: border-box;
    padding: 12px;
    width: calc(100% - 40px);
    background: #fff;
  }
}
</style>