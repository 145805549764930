import { Request, HTTP_TYPE_ENUM } from './api'

/**
 * 获取列表数据
 * @param {*} body
 * @returns
 */
export const GetIndexTableDataRequest = body =>
  Request({
    url: '/category/getPage',
    body,
    method: HTTP_TYPE_ENUM.GET,
  })

/**
 * 获取树状结构数据
 * @param {*} body
 * @returns
 */
export const GetIndexTreeDataRequest = body =>
  Request({
    url: '/category/getTree',
    body,
    method: HTTP_TYPE_ENUM.GET,
  })

/**
 * 获取详情数据
 * @param {*} body
 * @returns
 */
export const GetDetailDataRequest = body =>
  Request({
    url: '/category/detail',
    body,
    method: HTTP_TYPE_ENUM.GET,
  })

/**
 * 编辑接口
 * @param {*} body
 * @returns
 */
export const EditDetailDataRequest = body =>
  Request({
    url: '/category/edit',
    body,
    method: HTTP_TYPE_ENUM.POST,
  })

/**
 * 新增
 * @param {*} body
 * @returns
 */
export const AddDataRequest = body =>
  Request({
    url: '/category/add',
    body,
    method: HTTP_TYPE_ENUM.POST,
  })

/**
 * 修改垂类状态
 * @param {*} body
 * @returns
 */
export const DeactivatedStatusRequest = body =>
  Request({
    url: '/category/disable',
    body,
    method: HTTP_TYPE_ENUM.POST,
  })

/**
 * 修改垂类状态
 * @param {*} body
 * @returns
 */
export const EnabledStatusRequest = body =>
  Request({
    url: '/category/enable',
    body,
    method: HTTP_TYPE_ENUM.POST,
  })
