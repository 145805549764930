export default class ExportGulp {
  constructor() {
    return this
  }

  static createAddAndEditDialogData(data) {
    const { id, name, described, sort, parentId } = data || {}

    return {
      id,
      parentId,
      name: name && name.trim(),
      sort,
      description: described,
    }
  }

  static createIndexTableSearchParamsData(data) {
    const { page, pageSize, parentId, name, level } = data || {}

    return {
      page,
      pageSize,
      parentId,
      name,
      level,
    }
  }
}
