<script>
import { defineComponent, computed, ref, watch } from '@vue/composition-api'
import Modal from '@/@core/components/Modal/index.vue'
import { useMessage } from '@/hooks/useMessage'
import { Form, FormItem, Input, Alert } from 'element-ui'
import { AddDataRequest, EditDetailDataRequest, GetDetailDataRequest } from '../service'
import ExportGulp from '../gulp/exportGulp'
import ImportGulp from '../gulp/importGulp'

export default defineComponent({
  components: {
    Modal,
    Form,
    FormItem,
    Input,
    Alert,
  },
  props: {
    visibly: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number || null,
      default: () => null,
    },
    parentId: {
      type: Number || null,
      default: () => null,
    },
  },
  setup(props, { emit }) {
    const { message } = useMessage()
    const formRef = ref(null)
    const form = ref({
      name: null,
      described: null,
      sort: 1,
    })
    const baseTitle = computed(() => (props.id ? '编辑' : '新增'))

    const getDetailInfo = async () => {
      console.log('props.id', props.id)

      const [msg, data] = await GetDetailDataRequest({ id: props.id })

      if (msg) {
        message.error(msg || '查询失败')

        return
      }
      const result = ImportGulp.createDetailInfoData(data.data)
      form.value = result
    }

    const clearData = () => {
      form.value = {
        name: null,
        described: null,
        sort: 1,
      }
      formRef.value.resetFields()
    }

    const handleClose = () => {
      clearData()
      emit('close')
    }

    const submitFormData = async () => {
      const RequestMethod = props.id ? EditDetailDataRequest : AddDataRequest

      const body = ExportGulp.createAddAndEditDialogData({
        ...form.value,
        id: props.id,
        parentId: props.parentId,
      })

      const [msg] = await RequestMethod(body)

      if (msg) {
        return Promise.reject()
      }

      message.success(`${baseTitle.value}成功`)

      return Promise.resolve()
    }

    const handleSubmit = async () => {
      formRef.value.validate(async result => {
        if (!result) return

        submitFormData().then(() => {
          clearData()
          emit('submit')
        })
      })
    }

    watch(
      () => props.visibly,
      () => {
        if (props.visibly && props.id) {
          getDetailInfo()
        }
      },
    )

    const validatePass = (_, value, callback) => {
      if (value > 200 || value < 1) return callback(new Error('请输入1-200正整数'))

      return callback()
    }

    return {
      formRef,
      form,
      baseTitle,
      handleClose,
      handleSubmit,
      validatePass,
    }
  },
})
</script>

<template>
  <Modal :visibly="visibly" width="450px" :title="baseTitle" @close="handleClose" @submit="handleSubmit">
    <template #body>
      <div v-if="id" class="mb-4">
        <div class="Alert">
          <i class="mr-2 alertIcon iconfont icon-a-tishishuoming2x2" />
          <div class="tipsTitle">编辑后，则频道引用该垂类内容也随之更新</div>
        </div>
      </div>
      <Form ref="formRef" :model="form" label-width="80px" label-position="left">
        <FormItem
          label="垂类名称"
          prop="name"
          required
          :rules="[
            { required: true, message: '请填写垂类名称' },
            { min: 0, max: 50, message: '垂类名称最多50个字符' },
          ]"
        >
          <Input v-model="form.name" placeholder="请输入" />
        </FormItem>

        <FormItem label="详细描述" prop="described">
          <Input
            v-model="form.described"
            type="textarea"
            rows="3"
            placeholder="请输入"
            :maxlength="300"
            show-word-limit
          />
        </FormItem>

        <FormItem
          label="排序"
          prop="sort"
          required
          :rules="[
            {
              required: true,
              message: '请填写排序',
            },
            {
              type: 'number',
              message: '请输入1-200正整数',
            },
            {
              validator: validatePass,
            },
          ]"
        >
          <div class="tips">数字越大，排序越靠前</div>
          <Input v-model.number="form.sort" placeholder="请输入1-200正整数" />
        </FormItem>
      </Form>
    </template>
  </Modal>
</template>

<style lang="scss" scoped>
::v-deep {
  .el-form-item {
    margin-bottom: 16px;

    &:nth-last-child(1) {
      margin-bottom: 0px;
    }

    &:nth-child(2){
      margin-bottom: 6px;
    }
  }

  .el-form-item__label {
    color: rgba(0, 0, 0, 0.8);
  }

  .categoryName {
    .el-form-item {
      margin-bottom: 6px;
    }
  }
}

.Alert {
  padding: 12px 14px;
  display: flex;
  background: rgba(251, 151, 22, 0.08);
  border-radius: 6px;
  align-items: center;
  box-sizing: border-box;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 12px;
  color: #4e2c00;
  line-height: 17px;
  text-align: left;
  font-style: normal;

  .alertIcon::before {
    width: 14px;
    height: 14px;
  }
}

.tips {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
  line-height: 17px;
  text-align: left;
  font-style: normal;
  margin-bottom: 8px;
  margin-top: 10px;
}
.tipsTitle {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 12px;
  color: #4e2c00;
  line-height: 17px;
  text-align: left;
  font-style: normal;
}
</style>
