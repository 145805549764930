<script>
import { defineComponent, onMounted, ref, nextTick } from '@vue/composition-api'
import SearchPaginationTable from '@core/components/SearchPaginationTable/index.vue'
import { Button } from 'element-ui'
import { useMessage } from '@/hooks/useMessage'
import StatusTips from '@core/components/xw-status/XwStatus.vue'
import AddDialog from './components/AddDialog.vue'
import DetailDialog from './components/DetailDialog.vue'
import UpdateStatusDialog from './components/UpdateStatusDialog.vue'
import SearchTree from './components/SearchTree.vue'
import { INDEX_TABLE_COLUMNS, SEARCH_CONFIG, STATUS_TYPE, STATUS_MAP } from './constants'
import { GetIndexTreeDataRequest, GetIndexTableDataRequest } from './service'
import ImportGulp from './gulp/importGulp'
import ExportGulp from './gulp/exportGulp'

export default defineComponent({
  components: {
    AddDialog,
    DetailDialog,
    SearchTree,
    Button,
    UpdateStatusDialog,
    SearchPaginationTable,
    StatusTips,
  },
  setup() {
    const { message } = useMessage()

    const TableRef = ref(null)
    const searchTreeRef = ref(null)

    const total = ref(0)
    const addDialogId = ref(null)
    const addDialogVisibility = ref(false)

    const detailDialogId = ref(null)
    const detailDialogVisibility = ref(false)
    const parentId = ref(null)

    const tableList = ref([])
    const treeData = ref([])
    const selectNodeId = ref(null)

    const updateStatusType = ref(null)
    const UpdateStatusDialogId = ref(null)
    const UpdateStatusDialogVisibility = ref(false)

    const getTreeData = async () => {
      const [msg, data] = await GetIndexTreeDataRequest()

      if (msg) {
        message.error(msg || '查询树状节点失败')

        return
      }

      const result = ImportGulp.createTreeData(data, selectNodeId.value)
      treeData.value = result
    }

    /**
     * 用于创建后刷新表格数据和树数据
     */
    const refresh = async () => {
      TableRef.value?.refresh()

      await getTreeData()

      // 防止选中tree获取新数据后被选中的背景没有了
      const pre = selectNodeId.value
      selectNodeId.value = null

      nextTick(() => {
        selectNodeId.value = pre
      })
    }

    const handleSearch = async value => {
      const searchParams = ExportGulp.createIndexTableSearchParamsData({
        ...value,
        parentId: selectNodeId.value,
      })

      const [msg, data] = await GetIndexTableDataRequest(searchParams)

      if (msg) {
        message.error(msg || '查询表格数据失败')

        return
      }
      tableList.value = ImportGulp.createIndexTableData(data.data)

      total.value = data.total
    }

    const handleReset = () => {
      selectNodeId.value = null
      refresh()
    }

    const handleClickAddData = () => {
      addDialogVisibility.value = true
    }

    const handleDetails = row => {
      detailDialogVisibility.value = true
      detailDialogId.value = row.id
    }

    const handleEdit = row => {
      addDialogVisibility.value = true
      addDialogId.value = row.id
    }

    const handleCloseAddDialog = () => {
      addDialogVisibility.value = false
      addDialogId.value = null
      parentId.value = null
    }

    const handleSubmitAddDialog = () => {
      // TODO: 编写添加数据功能

      searchTreeRef.value.handleExpandNode({
        id: parentId.value,
      })

      refresh()
      handleCloseAddDialog()
    }

    const handleCloseDetailDialog = () => {
      detailDialogVisibility.value = false
    }

    const handleSubmitDetailDialog = () => {
      // TODO: 编写编辑数据功能
      refresh()
      handleCloseDetailDialog()
    }

    const handleCreateChildren = row => {
      parentId.value = row.id
      addDialogVisibility.value = true
    }

    const handleDeletedTreeNode = async row => {
      // TODO: 编写删除节点功能

      UpdateStatusDialogVisibility.value = true
      UpdateStatusDialogId.value = row.id
      updateStatusType.value = STATUS_TYPE.OFF
    }

    const handleEditTreeNode = row => {
      parentId.value = row.parentId
      addDialogId.value = row.id
      addDialogVisibility.value = true
    }

    const handleSelectNode = row => {
      selectNodeId.value = row.id
      TableRef.value?.refresh()
    }

    const handelCloseUpdateStatusDialog = () => {
      updateStatusType.value = null
      UpdateStatusDialogVisibility.value = false
      UpdateStatusDialogId.value = null
    }

    const handelSubmitUpdateStatusDialog = () => {
      selectNodeId.value = null

      handelCloseUpdateStatusDialog()
      refresh()
    }

    const handleEnabled = async row => {
      updateStatusType.value = STATUS_TYPE.ON
      UpdateStatusDialogId.value = row.id
      UpdateStatusDialogVisibility.value = true
    }

    onMounted(() => {
      getTreeData()
    })

    return {
      updateStatusType,
      searchTreeRef,
      TableRef,
      parentId,
      addDialogId,
      addDialogVisibility,
      treeData,
      total,
      INDEX_TABLE_COLUMNS,
      SEARCH_CONFIG,
      tableList,
      detailDialogId,
      detailDialogVisibility,
      UpdateStatusDialogVisibility,
      UpdateStatusDialogId,
      selectNodeId,
      STATUS_MAP,
      STATUS_TYPE,

      handleSearch,
      handleReset,
      handleClickAddData,
      handleDetails,
      handleEdit,
      handleCloseAddDialog,
      handleSubmitAddDialog,
      handleSubmitDetailDialog,
      handleCloseDetailDialog,
      handleCreateChildren,
      handleDeletedTreeNode,
      handleEditTreeNode,
      handleSelectNode,
      handelSubmitUpdateStatusDialog,
      handelCloseUpdateStatusDialog,
      handleEnabled,
    }
  },
})
</script>

<template>
  <div class="CategoryContainer">
    <div class="VCSettingWrap">
      <div class="searchTreeWrap">
        <div class="title">全部垂类</div>
        <SearchTree
          ref="searchTreeRef"
          :data="treeData"
          :current-node-key="selectNodeId"
          @create="handleCreateChildren"
          @deleted="handleDeletedTreeNode"
          @edit="handleEditTreeNode"
          @click="handleSelectNode"
        >
          <template #top>
            <div class="addRoot" @click="handleClickAddData">
              <v-img class="addIcon" src="@/assets/images/verticalCategorySetting/addIcon@3x.png"></v-img>
              <span>新增一级垂类</span>
            </div>
          </template>
        </SearchTree>
      </div>
      <div class="listWrap">
        <div class="title">垂类列表</div>

        <div class="listContent">
          <SearchPaginationTable
            ref="TableRef"
            :columns="INDEX_TABLE_COLUMNS"
            :search-config="SEARCH_CONFIG"
            :data="tableList"
            :total="total"
            :rest-is-search="false"
            :page-size="20"
            :page-sizes="[20, 50, 100]"
            height="540px"
            @search="handleSearch"
            @reset="handleReset"
          >
            <template #status="{ row }">
              <div class="status">
                <StatusTips :status="row.status" :status-map="STATUS_MAP" />
                <span>{{ row.statusTxt }}</span>
              </div>
            </template>
            <template #opts="{ row }">
              <Button type="text" @click="handleDetails(row)"> 查看 </Button>
              <Button v-if="row.status === STATUS_TYPE.OFF" type="text" @click="handleEnabled(row)"> 启用 </Button>
            </template>
          </SearchPaginationTable>
        </div>
      </div>
    </div>
    <AddDialog
      :id="addDialogId"
      :parent-id="parentId"
      :visibly="addDialogVisibility"
      @close="handleCloseAddDialog"
      @submit="handleSubmitAddDialog"
    />
    <DetailDialog
      :id="detailDialogId"
      :visibly="detailDialogVisibility"
      @close="handleCloseDetailDialog"
      @submit="handleSubmitDetailDialog"
    />
    <UpdateStatusDialog
      :id="UpdateStatusDialogId"
      :visibly="UpdateStatusDialogVisibility"
      :type="updateStatusType"
      @close="handelCloseUpdateStatusDialog"
      @submit="handelSubmitUpdateStatusDialog"
    />
  </div>
</template>

<style lang="scss" scoped>
@import url('./style.scss');
</style>
