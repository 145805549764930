<script>
import { defineComponent, computed } from '@vue/composition-api'
import CheckTipsModal from '@core/components/CheckTipsModal'
import { useMessage } from '@/hooks/useMessage'
import { DeactivatedStatusRequest, EnabledStatusRequest } from '../service'
import { STATUS_TYPE } from '../constants'

export default defineComponent({
  components: {
    CheckTipsModal,
  },
  props: {
    visibly: {
      type: Boolean,
      default: false,
      required: true,
    },
    type: {
      type: Number,
      default: null,
    },
    id: {
      type: Number,
      default: null,
    },
  },
  setup(props, { emit }) {
    const { message } = useMessage()

    const handleCloseDialog = () => emit('close', false)

    const titleTxt = computed(() => {
      if (props.type === STATUS_TYPE.ON) {
        return '启用'
      }

      return '停用'
    })

    const tipsTxt = computed(() => {
      if (props.type === STATUS_TYPE.ON) {
        return '启用垂类后，该垂类内容则能被频道引用，是否确定启用该垂类？'
      }

      return '停用垂类后，该垂类内容则不能被频道引用，是否确定停用该垂类？'
    })

    const updateStatus = async id => {
      const Request = props.type === STATUS_TYPE.ON ? EnabledStatusRequest : DeactivatedStatusRequest

      const [msg] = await Request({ id })

      console.log('msg', msg)

      if (msg) {
        return Promise.reject()
      }

      message.success('成功')

      return Promise.resolve()
    }
    const handleSubmitDialog = async () => {
      await updateStatus(props.id)
      emit('submit', false)
    }

    return {
      tipsTxt,
      titleTxt,
      handleCloseDialog,
      handleSubmitDialog,
    }
  },
})
</script>

<template>
  <div>
    <CheckTipsModal
      width="400px"
      :title="titleTxt"
      :content-title="tipsTxt"
      :visibly="visibly"
      @close="handleCloseDialog"
      @submit="handleSubmitDialog"
    >
    </CheckTipsModal>
  </div>
</template>

<style lang="scss" scoped>
.message {
  font-family: PingFang-SC, PingFang-SC;
  font-weight: 500;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 22px;
  text-align: left;
  font-style: normal;
  margin-bottom: 16px;
  display: flex;
  align-items: center;

  .icon {
    display: block;
    line-height: 22px;
    width: 14px;
    height: 14px;
    flex-grow: 0;
    margin-right: 8px;
  }
}

.tipsContent {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
  line-height: 20px;
  text-align: left;
  font-style: normal;
}

.tipsTitle {
  margin-bottom: 8px;
}

.listContent {
  background: #f6f7f8;
  border-radius: 4px;
  padding: 12px;
  box-sizing: border-box;
}
</style>
