<script>
import { defineComponent, onMounted, reactive, ref } from '@vue/composition-api'
import ViewWrap from '../ViewWrap/index.vue'
import Table from '../xw-table/XwTable.vue'
import Pagination from '../Pagination/index.vue'
import SearchForm from '../search-form/SearchForm.vue'

export default defineComponent({
  components: {
    SearchForm,
    Pagination,
    Table,
    ViewWrap,
  },
  props: {
    searchConfig: {
      type: Array,
      default: () => [],
      required: true,
    },
    columns: {
      type: Array,
      default: () => [],
      required: true,
    },
    data: {
      type: Array,
      default: () => [],
      required: true,
    },
    isInit: {
      type: Boolean,
      default: true,
    },
    page: {
      type: Number,
      default: 1,
    },
    pageSizes: {
      type: Array,
      default: () => [10, 20, 30],
    },
    pageSize: {
      type: Number,
      default: 10,
    },
    total: {
      type: Number,
      default: 0,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    // 是否点击重置给你自动调用search事件
    restIsSearch: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    const tableRef = ref(null)
    const paginationForm = reactive({
      page: props.page || 1,
      pageSize: props.pageSize || 10,
    })

    const searchForm = ref({})

    const resetPagination = () => {
      paginationForm.page = 1
      paginationForm.pageSize = props.pageSize
    }

    const handleSearch = v => {
      searchForm.value = v
      resetPagination()

      emit('search', {
        ...paginationForm,
        ...v,
      })
    }

    const handleRestReset = v => {
      searchForm.value = v
      resetPagination()

      emit('reset', {
        ...paginationForm,
        ...v,
      })
    }

    const handleChangePageSize = v => {
      paginationForm.pageSize = v
      paginationForm.page = 1

      emit('search', {
        ...paginationForm,
        ...searchForm.value,
      })
    }

    const handleCurrentChange = v => {
      paginationForm.page = v

      emit('search', {
        ...paginationForm,
        ...searchForm.value,
      })
    }

    const handleChangeValue = v => {
      searchForm.value = { ...searchForm.value, [v.key]: v.value }
    }

    const refresh = () => handleSearch(searchForm.value)

    onMounted(() => {
      // eslint-disable-next-line no-unused-expressions
      props.isInit && handleSearch()
    })

    return {
      searchForm,
      tableRef,
      paginationForm,
      refresh,
      handleSearch,
      handleRestReset,
      handleCurrentChange,
      handleChangePageSize,
      handleChangeValue,
    }
  },
})
</script>

<template>
  <ViewWrap>
    <template #header>
      <search-form
        :rest-is-search="restIsSearch"
        :form-config="searchConfig"
        @search="handleSearch"
        @reset="handleRestReset"
        @input="handleChangeValue"
      />
    </template>

    <template #top>
      <slot name="top" />
    </template>

    <template>
      <div class="tableWrap">
        <Table :loading="loading" v-bind="$attrs" ref="tableRef" :columns="columns" :rows="data">
          <template v-for="i in columns" #[i.slots]="{ row }">
            <slot :name="i.slots" :row="row" />
          </template>
        </Table>
      </div>
    </template>

    <template #footer>
      <Pagination
        :page-index="paginationForm.page"
        :page-size="paginationForm.pageSize"
        :total="total"
        :page-sizes="pageSizes"
        @size-change="handleChangePageSize"
        @current-change="handleCurrentChange"
      />
    </template>

    <slot name="dialog" />
  </ViewWrap>
</template>

<style lang="scss" scoped>
.tableWrap {
  height: 100%
}
</style>
