import { CATEGORY_LABEL, STATUS_LABEL } from '../constants'

export default class ImportGulp {
  constructor() {
    return this
  }

  static createIndexTableData(list) {
    console.log('list', list)

    if (!list || list.length === 0) return []

    return list.map(data => ImportGulp.createDetailInfoData(data))
  }

  static createTreeData(list) {
    if (!list || list.length === 0) return []

    return list.map(i => {
      const { id, parentId, name, level, sort, opened, description, creatorName, createAt } = i || {}
      const children = ImportGulp.createTreeData(i.children)

      return {
        id,
        parentId,
        label: name,
        level,
        sort,
        status: opened,
        description,
        createUserName: creatorName,
        createTime: createAt,
        children,
        isExpand: false,
      }
    })
  }

  static createDetailInfoData(data) {
    const {
      id,
      name,
      parentId,
      level,
      parentName,
      opened,
      sort,
      description,
      creatorName,
      createdAt,
      updatedAt,
      updatorName,
    } = data || {}
    const levelName = CATEGORY_LABEL[level]
    const statusTxt = STATUS_LABEL[opened]

    return {
      id,
      name,
      parentId,
      sort,
      described: description,
      createUserName: creatorName,
      createTime: createdAt,
      updateUserName: updatorName,
      updateTime: updatedAt,
      level,
      status: opened,
      statusTxt,
      parentName,
      levelName,
    }
  }
}
