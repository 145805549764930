export const CATEGORY_TYPE = {
  ONE: 1,
  TWO: 2,
  THREE: 3,
}
export const STATUS_TYPE = {
  ON: 1,
  OFF: 0,
}

export const STATUS_LABEL = {
  [STATUS_TYPE.ON]: '启用',
  [STATUS_TYPE.OFF]: '禁用',
}

export const STATUS_MAP = {
  SUCCESS: [STATUS_TYPE.ON],
  FAIL: [STATUS_TYPE.OFF],
}

export const CATEGORY_LABEL = {
  [CATEGORY_TYPE.ONE]: '一级',
  [CATEGORY_TYPE.TWO]: '二级',
  [CATEGORY_TYPE.THREE]: '三级',
}

export const CATEGORY_LIST = [
  {
    label: CATEGORY_LABEL[CATEGORY_TYPE.ONE],
    value: CATEGORY_TYPE.ONE,
  },
  {
    label: CATEGORY_LABEL[CATEGORY_TYPE.TWO],
    value: CATEGORY_TYPE.TWO,
  },
  {
    label: CATEGORY_LABEL[CATEGORY_TYPE.THREE],
    value: CATEGORY_TYPE.THREE,
  },
]

export const INDEX_TABLE_COLUMNS = [
  {
    text: '垂类名称',
    value: 'name',
    width: 150,
  },
  {
    text: '垂类层级',
    value: 'levelName',
    width: 80,
  },
  {
    text: '上级垂类',
    value: 'parentName',
    width: 150,
  },
  {
    text: '垂类描述',
    value: 'described',
  },
  {
    text: '排序',
    value: 'sort',
    width: 50,
    align: 'right',
  },
  {
    text: '状态',
    value: 'status',
    slots: 'status',
    width: 100,
  },
  {
    text: '创建时间',
    value: 'createTime',
    width: 170,
  },
  {
    text: '创建人',
    value: 'createUserName',
    width: 100,
  },
  {
    text: '操作',
    sortable: false,
    value: 'opts',
    slots: 'opts',
    width: 120,
    fixed: 'right',
  },
]

export const SEARCH_CONFIG = [
  {
    element: 'VTextField',
    props: 'name',
    placeholder: '请输入',
    label: '垂类名称',
  },
  {
    element: 'VAutocomplete',
    props: 'level',
    placeholder: '请选择',
    items: CATEGORY_LIST,
    multiple: false,
    itemText: 'label',
    itemValue: 'value',
    label: '垂类层级',
    clearable: false,
  },
]
