<script>
import { defineComponent } from '@vue/composition-api'
import { Drawer, Button } from 'element-ui'

export default defineComponent({
  components: {
    Drawer,
    Button,
  },
  props: {
    visibly: {
      type: Boolean,
      default: false,
    },
    disabledSubmit: {
      type: Boolean,
      default: false,
    },
    showSubmitButton: {
      type: Boolean,
      default: true,
    },
    zIndex: {
      type: Number,
      default: 2008,
    },
    closeButtonTxt: {
      type: String,
      default: '取消',
    },
    submitButtonTxt: {
      type: String,
      default: '确定',
    },
  },
  setup(props, { emit }) {
    const handleClose = () => emit('close', false)

    const handleSubmit = () => emit('submit', false)

    return { handleClose, handleSubmit }
  },
})
</script>

<template>
  <Drawer :z-index="zIndex" class="customDrawer" v-bind="$attrs" :visible="visibly" @close="handleClose">
    <div class="DrawerContainer">
      <slot name="top" />
      <div class="wrap">
        <div v-if="$slots.body" class="contentWrap">
          <slot name="body" />
        </div>

        <div class="footerWrap">
          <slot name="footer" />

          <div v-if="!$slots.footer" class="footerButtonWrap">
            <Button @click="handleClose">{{ closeButtonTxt }}</Button>
            <Button v-if="showSubmitButton" :disabled="disabledSubmit" type="primary" @click="handleSubmit">
              {{ submitButtonTxt }}
            </Button>
          </div>
        </div>
      </div>
    </div>
  </Drawer>
</template>

<style lang="scss">
.customDrawer {
  .el-drawer__header {
    margin-bottom: 0;
    padding: 20px 20px 12px 20px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 20px;
    color: #000000;
    line-height: 28px;
    text-align: left;
    font-style: normal;
  }
}
</style>

<style lang="scss" scoped>
.DrawerContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background: #f6f7f8;
}
.wrap {
  width: 100%;
  position: relative;
  background: #f6f7f8;
  box-sizing: border-box;
  padding: 12px 20px;
  padding-bottom: 68px;
  flex: 1;

  .contentWrap {
    height: 100%;
    box-sizing: border-box;
    background: #ffffff;
    overflow-y: scroll;
    border-radius: 6px;
  }

  .footerWrap {
    display: flex;
    justify-content: flex-end;
    padding: 14px 20px;
    box-sizing: border-box;
    width: 100%;
    height: 60px;
    position: absolute;
    bottom: 0;
    left: 0;
    background: #ffffff;
  }
}
</style>
