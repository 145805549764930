<script>
import { defineComponent, watch, ref } from '@vue/composition-api'
import Dialog from '@/@core/components/Modal/index.vue'
import { Tooltip } from 'element-ui'

export default defineComponent({
  components: {
    Dialog,
    Tooltip,
  },
  props: {
    visibly: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '提示',
    },
    contentTitle: {
      type: String,
      default: '默认提示内容',
    },
    tipsTitle: {
      type: String,
      default: '默认小行提示内容',
    },
    tooltipList: {
      type: Array,
      default: () => [],
    },
    tipsList: {
      type: Array,
      default: () => [],
    },
    loadingSubmit: {
      type: Boolean,
      default: false,
    },
    itemLabelTitle: {
      type: String,
      default: '默认名称',
    },
    itemValueTitle: {
      type: String,
      default: '默认名称',
    },
  },
  setup(props, { emit }) {
    const handleSubmitDialog = async () => {
      emit('submit')
    }
    const handleCloseDialog = () => {
      emit('close')
    }

    return {
      handleSubmitDialog,
      handleCloseDialog,
    }
  },
})
</script>
<template>
  <div>
    <Dialog
      v-bind="$attrs"
      :title="title"
      :loading-submit="loadingSubmit"
      :visibly="visibly"
      @close="handleCloseDialog"
      @submit="handleSubmitDialog"
    >
      <template #body>
        <div class="bodyWrap">
          <div v-if="tooltipList.length <= 1" class="message">
            <v-img class="icon" src="@/assets/images/verticalCategorySetting/warning@3x.png" />
            <div>
              {{ contentTitle }}
            </div>
          </div>

          <div v-if="tooltipList.length > 1" class="message">
            <v-img class="icon" src="@/assets/images/verticalCategorySetting/warning@3x.png" />
            {{ contentTitle }}
            <Tooltip placement="right">
              <template #content>
                <slot name="tooltipsContent" :data="tooltipList">
                  <div v-for="i in tooltipList" :key="i.value" class="titleItem">{{ i.label }}</div>
                </slot>
              </template>
              <span class="titleInfo">详情</span>
            </Tooltip>
          </div>

          <div v-if="tipsList.length !== 0" class="tipsContent">
            <div class="tipsTitle">{{ tipsTitle }}</div>
            <slot name="list" :list="tipsList">
              <div v-for="(item, index) in tipsList" :key="item.value" class="listContent">
                <slot name="listItem" :item="item" :index="index">
                  <div class="listItem">
                    <div class="label mb-1">{{ itemLabelTitle }}{{ item.label }}</div>
                    <div class="value">{{ itemValueTitle }}{{ item.value }}</div>
                  </div>
                </slot>
              </div>
            </slot>
          </div>
        </div>
      </template>
    </Dialog>
  </div>
</template>

<style lang="scss" scoped>
.titleItem::v-deep {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  line-height: 22px;
  text-align: left;
  font-style: normal;
  margin-bottom: 4px;

  &:nth-last-child(1) {
    margin-bottom: 0;
  }
}

.titleInfo {
  font-family: PingFang-SC, PingFang-SC;
  font-weight: 500;
  font-size: 14px;
  color: #9155fd;
  line-height: 22px;
  text-align: left;
  font-style: normal;
}

.message {
  display: flex;
  font-family: PingFang-SC, PingFang-SC;
  font-weight: 500;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 22px;
  text-align: left;
  font-style: normal;
  margin-bottom: 16px;
  vertical-align: middle;
  text-wrap: wrap;

  .icon {
    display: inline-block;
    line-height: 22px;
    width: 14px;
    height: 14px;
    flex-grow: 0;
    margin-right: 6px;
    vertical-align: middle;
    margin-bottom: 2px;
    margin-top: 4px;
  }
}

.tipsContent {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
  line-height: 20px;
  text-align: left;
  font-style: normal;
}

.tipsTitle {
  margin-bottom: 8px;
}

.listContent {
  background: #f6f7f8;
  border-radius: 4px;
  padding: 12px;
  box-sizing: border-box;
  margin-bottom: 8px;

  &:nth-last-child(1) {
    margin-bottom: 0px;
  }
}
</style>

