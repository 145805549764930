import axios from '@axios'
import config from '../../../config'

const prefix = config.serviceUrl

export const HTTP_TYPE_ENUM = {
  GET: 'get',
  PUT: 'put',
  POST: 'post',
  DELETED: 'delete',
}

const createHttpTypeNumMap = () => {
  const map = new Map()
  const keys = Object.keys(HTTP_TYPE_ENUM)

  keys.forEach(k => map.set(HTTP_TYPE_ENUM[k], HTTP_TYPE_ENUM[k]))

  return map
}

const getRequestMethod = string => {
  if (!string) return axios.get

  const map = createHttpTypeNumMap()

  if (!map.has(string)) return axios.get

  return axios[string]
}

const createRequestParams = (body, string) => {
  if (string === HTTP_TYPE_ENUM.GET) {
    return { params: body }
  }

  return body
}

export const Request = async ({ url, body, method }) => {
  const request = getRequestMethod(method)
  const params = createRequestParams(body, method)

  try {
    const result = await request(`${prefix}${url}`, params)
    const { status, data, message } = result

    if (status !== 200) return [message, null, result]

    return [null, data]
  } catch (err) {
    console.log('err', err)

    return [err, null, null]
  }
}
