var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Modal',{attrs:{"visibly":_vm.visibly,"width":"450px","title":_vm.baseTitle},on:{"close":_vm.handleClose,"submit":_vm.handleSubmit},scopedSlots:_vm._u([{key:"body",fn:function(){return [(_vm.id)?_c('div',{staticClass:"mb-4"},[_c('div',{staticClass:"Alert"},[_c('i',{staticClass:"mr-2 alertIcon iconfont icon-a-tishishuoming2x2"}),_c('div',{staticClass:"tipsTitle"},[_vm._v("编辑后，则频道引用该垂类内容也随之更新")])])]):_vm._e(),_c('Form',{ref:"formRef",attrs:{"model":_vm.form,"label-width":"80px","label-position":"left"}},[_c('FormItem',{attrs:{"label":"垂类名称","prop":"name","required":"","rules":[
          { required: true, message: '请填写垂类名称' },
          { min: 0, max: 50, message: '垂类名称最多50个字符' } ]}},[_c('Input',{attrs:{"placeholder":"请输入"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('FormItem',{attrs:{"label":"详细描述","prop":"described"}},[_c('Input',{attrs:{"type":"textarea","rows":"3","placeholder":"请输入","maxlength":300,"show-word-limit":""},model:{value:(_vm.form.described),callback:function ($$v) {_vm.$set(_vm.form, "described", $$v)},expression:"form.described"}})],1),_c('FormItem',{attrs:{"label":"排序","prop":"sort","required":"","rules":[
          {
            required: true,
            message: '请填写排序',
          },
          {
            type: 'number',
            message: '请输入1-200正整数',
          },
          {
            validator: _vm.validatePass,
          } ]}},[_c('div',{staticClass:"tips"},[_vm._v("数字越大，排序越靠前")]),_c('Input',{attrs:{"placeholder":"请输入1-200正整数"},model:{value:(_vm.form.sort),callback:function ($$v) {_vm.$set(_vm.form, "sort", _vm._n($$v))},expression:"form.sort"}})],1)],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }