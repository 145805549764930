<script>
import { defineComponent, ref, watch } from '@vue/composition-api'
import Drawer from '@/@core/components/Drawer/index.vue'
import { useMessage } from '@/hooks/useMessage'
import { Divider, Descriptions, DescriptionsItem } from 'element-ui'
import TitleContent from '@/@core/components/TitleContent/index.vue'
import StatusTips from '@core/components/xw-status/XwStatus.vue'
import { GetDetailDataRequest } from '../service'
import ImportGulp from '../gulp/importGulp'
import { STATUS_MAP } from '../constants'

export default defineComponent({
  components: {
    Drawer,
    Divider,
    Descriptions,
    DescriptionsItem,
    TitleContent,
    StatusTips,
  },
  props: {
    visibly: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String || Number,
      default: '',
    },
  },
  setup(props, { emit }) {
    const { message } = useMessage()

    const info = ref({})

    const getDetailInfo = async () => {
      console.log('props.id', props.id)

      const [msg, data] = await GetDetailDataRequest({ id: props.id })

      if (msg) {
        message.error(msg || '查询失败')

        return
      }
      const result = ImportGulp.createDetailInfoData(data.data)
      info.value = result
    }

    const handleClose = () => {
      emit('close')
    }

    watch(
      () => props.visibly,
      () => {
        if (props.visibly && props.id) {
          getDetailInfo()
        }
      },
    )

    return {
      info,
      STATUS_MAP,
      handleClose,
    }
  },
})
</script>

<template>
  <Drawer
    close-button-txt="关闭"
    :show-submit-button="false"
    title="垂类详情"
    size="750px"
    :visibly="visibly"
    @close="handleClose"
  >
    <template #top>
      <div class="topInfoWrap">
        <div class="createInfo">创建人：{{ info.createUserName || '暂无' }} {{ info.createTime }}</div>
        <template v-if="info.updateUserName">
          <Divider direction="vertical" />
          <div class="updateInfo">更新人：{{ info.updateUserName || '暂无' }} {{ info.updateTime }}</div>
        </template>
      </div>
    </template>
    <template #body>
      <div class="infoWrap">
        <TitleContent label="垂类信息">
          <Descriptions :column="1" border>
            <DescriptionsItem label="垂类ID" label-class-name="labelClassName" content-class-name="contentClassName">
              {{ info.id }}
            </DescriptionsItem>
            <DescriptionsItem label="垂类名称" label-class-name="labelClassName" content-class-name="contentClassName">
              {{ info.name }}
            </DescriptionsItem>
            <DescriptionsItem label="状态" label-class-name="labelClassName" content-class-name="contentClassName">
              <div class="status">
                <StatusTips :status="info.status" :status-map="STATUS_MAP" />
                <span>{{ info.statusTxt }}</span>
              </div>
            </DescriptionsItem>
            <DescriptionsItem label="垂类层级" label-class-name="labelClassName" content-class-name="contentClassName">
              {{ info.levelName }}
            </DescriptionsItem>
            <DescriptionsItem label="上级垂类" label-class-name="labelClassName" content-class-name="contentClassName">
              {{ info.parentName || '-' }}
            </DescriptionsItem>
            <DescriptionsItem label="排序" label-class-name="labelClassName" content-class-name="contentClassName">
              {{ info.sort }}
            </DescriptionsItem>
            <DescriptionsItem label="详细描述" label-class-name="labelClassName" content-class-name="contentClassName">
              {{ info.described || '-' }}
            </DescriptionsItem>
          </Descriptions>
        </TitleContent>
      </div>
    </template>
    <div></div>
  </Drawer>
</template>

<style lang="scss" scoped>
::v-deep {
  .labelClassName {
    width: 156px;
    color: rgba(0, 0, 0, 0.5);
  }
  .contentClassName {
    color: #000000;
    min-width: 220px;
  }
}

.topInfoWrap {
  padding: 16px 20px;
  display: flex;
  align-items: center;
  border-top: 1px solid #eee;
  background: #fff;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.4);
  line-height: 20px;
  text-align: left;
  font-style: normal;

  .createInfo {
    margin-right: 24px;
  }

  .updateInfo {
    margin-left: 24px;
  }
}

.infoWrap {
  padding: 20px;
  box-sizing: border-box;
}

.status {
  display: flex;
  align-items: center;
}
</style>
