<script>
import { defineComponent } from '@vue/composition-api'
import { Dialog, Button } from 'element-ui'

export default defineComponent({
  components: {
    Dialog,
    Button,
  },
  props: {
    visibly: {
      type: Boolean,
      default: false,
    },
    loadingSubmit: {
      type: Boolean,
      default: false,
    },
    zIndex: {
      type: Number,
      default: 2008,
    },
  },
  setup(props, { emit }) {
    const handleClose = () => emit('close', false)

    const handleSubmit = () => emit('submit', false)

    return { handleClose, handleSubmit }
  },
})
</script>

<template>
  <Dialog
    destroy-on-close
    :z-index="zIndex"
    class="modal customModal"
    top="23vh"
    v-bind="$attrs"
    :visible="visibly"
    @close="handleClose"
  >
    <div class="divider" />

    <div class="wrap">
      <div v-if="$slots.body" class="contentWrap">
        <slot name="body" />
      </div>

      <div class="footerWrap">
        <slot name="footer">
          <div class="footerButtonWrap">
            <Button @click="handleClose">取消</Button>
            <Button type="primary" :loading="loadingSubmit" @click="handleSubmit">确定</Button>
          </div>
        </slot>
      </div>
    </div>
  </Dialog>
</template>

<style lang="scss">
.modal.customModal {
  .el-dialog {
    border-radius: 6px;
  }

  .el-dialog__header {
    padding: 12px 24px;

    .el-dialog__title {
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      font-size: 16px;
      color: #000000;
      line-height: 22px;
      text-align: left;
      font-style: normal;
    }

    .el-dialog__headerbtn {
      top: 12px;
      right: 24px;
    }
  }

  .el-dialog__body {
    padding: 20px 24px;
    padding-top: 0px;
  }
}
</style>

<style lang="scss" scoped>
.modal {
  .divider {
    width: 100%;
    height: 1px;
    background: #f2f3f5;
  }

  .wrap {
    width: 100%;
    height: 100%;
    position: relative;
    box-sizing: border-box;
    padding-top: 20px;

    .contentWrap {
      padding-bottom: 20px;
      box-sizing: border-box;
    }

    .footerWrap {
      display: flex;
      justify-content: flex-end;
      box-sizing: border-box;
      width: 100%;
      background: #ffffff;
    }
  }
}
</style>
